import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../css/App.css';
import '../css/Home.css';
import calendarImage1 from '../images/familjens-kalender-demo-2022-januari.png';
import icon from '../svg/checkmark-40319.svg'
import pdfExample from '../pdfs/familjens-kalender-demo-2022-januari.pdf'
function HomePage() {

  let listStyle =
  {
    listStyleImage: `url(${icon})`,
    listStylePosition: 'inside',
  }
  return (
    <div className="home-big-wrapper">
      <Helmet htmlAttributes={{lang: 'sv'}}>
        <meta charSet="utf-8" />
        <title>Familjens kalender - skapa en egen personlig kalender</title>
        <meta name="description" content="Familjens kalender är en app för att lätt designa och skapa en egen gratis personlig kalender med egna bilder. Skriv ut din personliga kalender som pdf." />
      </Helmet>
      <div className="page-default-grid-layout">

        <header className="app-header">
          <div className="app-header-aside-left">
          </div>
          <div className="app-header-center">
            <h1>Familjens kalender</h1> 
            <h2>Skapa en egen personlig kalender</h2>
            <h3>Dela din personliga kalender med familj och vänner som PDF eller som utskrift</h3>
            <button className="app-header-button-click-me"><Link to="/kalender">Designa din kalender</Link></button>
          </div>  
          <div className="app-header-aside-right">
          </div>
        </header>        
        
        <nav className="app-nav">
          <Navigation />
        </nav>        
        
        <main className="page-home-main home-main">
          
          <section className="main-intro main-section"> 
            <h1>Familjens kalender</h1>
            <br/>
            <p>
              <strong>Familjens kalender är en webbapplikation där du gratis och enkelt kan skapa en egen personlig kalender.</strong>
            </p>
            <br/>

            <p> 
              Din personliga kalender hjälper dig inte bara att hålla koll på årets dagar utan även på dina nära och kära.

              Välja ut några av dina favoritbilder du vill ska visas i kalender, kanske årets höjdpunkter. 
              Lägga till födelsedagar och namnsdagar på familj och vänner så att de blir synliga i kalendern.
            </p>
            <br/>
            <p> 
              Du kan skapa en kalender till dig själv, din familj eller till någon annan. 
              Det kan också vara en uppskattad gåva där du kan dela gemensamma minnen eller sånt du tror personen kan uppskatta.
            </p>
            <br/>
            <p><strong>Lycka till med din <Link to="/kalender">personliga kalender</Link></strong>!</p>
          </section>

          <section className="calendar-instructions main-section">
              <h1>Skapa en personlig kalender</h1>
              <br/>
              <p><strong>Gå till sidan <Link to="/kalender">Kalender</Link> eller
               klicka på knappen <i>Designa din kalender</i> ovan för att komma igång med att skapa en ny personlig kalender.</strong></p>
              <br/>

              <p>
                Det finns inställningar för att välja till exempel kalenderår, för att lägga till födelsedagar och namnsdagar
                samt möjlighet att välja olika layouter för dina bilder. Du kan även spara och ladda upp tidigare kalenderinställningar. 
              </p>
              <br/>

              <p>
                När du känner dig nöjd med din designade kalender kan du lätt skriva ut den som en PDF. 
                Du kan sedan skriva ut kalendern till papper eller dela den med andra. 
              </p>
              <br/>

              <p>
                Vill du läsa instruktioner och råd kring hur du skapar en personlig kalender kan du
                gå till sidan <strong><Link to="/instruktioner">Instruktioner</Link>.</strong>
              </p>
          </section>


          <section className="calendar-examples main-section">
            <h2>Exempel på en kalender</h2>
            <br/>
            <strong>Bilden nedan är ett exempel på hur en personlig kalender kan se ut medan du arbetar med den.
               </strong> <br/><br/> Resultatet efter att du sparar kalendern är en <a href={pdfExample} target="_blank" rel="noreferrer">PDF</a>.
            <p><a href={calendarImage1} target="_blank" rel="noreferrer">
              <StaticImage
                src="../images/familjens-kalender-demo-2022-januari.png"
                alt="Bild på en personlig kalender"
                className='calendar-image'
              />               
            </a></p>
          </section>

          <section className="calendar-features main-section">
            <h2>Vad du kan göra</h2>
            <br/>
            <strong>När du skapar din personliga kalender så är några funktioner att du kan:</strong>
            <br/>
            <br/>
            <ul className="feature-list" style={listStyle}>
              
              <li>Lägga till födelsedagar i kalendern</li>
              <li>Lägga till namnsdagar som ska sticka ut i kalendern</li>
              <li>Välja egna bilder, upp till 16 bilder per kalendermånad</li>
              <li>Välja om du vill visa alla namnsdagar, röda dagar eller flaggdagar</li>
              <li>Zooma in och ut bilder samt flytta runt dem för att få med rätt innehåll</li>
              <li>Spara inställningar för nästa års kalender</li>
              <li>Spara kalendern som en PDF</li>
              <li>Dela kalendern med andra</li>
            </ul>

          </section>

        </main>

        <Footer/>
      </div>
    </div>
  )
}

export default HomePage;